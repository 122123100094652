export default {
  strings: {
    "start" : "Начать",
    "community": "Сообщество",
    "download": "Скачать",
    "connectingWait": "Ожидаем запуска нового рана...",
    "cancel": "Отмена",
    "whoAreYou": "Кто ты?",
    "play": "Старт",
    "settings": "Настройки",
    "timings": "Время",
    "chances": "Шансы",
    "subsandbits": "Сабы/битсы",
    "vote": "Опрос",
    "s": " сек",
    "m": "мин",
    "delay": "Пауза",
    "subtime": "Сабы",
    "events": "События",
    "items": "Предметы",
    "trinkets": "Тринкеты",
    "other": "Другое",
    "removeItems": "Забрать предмет",
    "removeItemsDesc": "Шанс заменить выдачу предмета",
    "subs": "Сабы",
    "bits": "Битсы",
    "superchat": "Суперчат",
    "followers": "Фолловеры",
    "hideVotes": "Скрыть голоса",
    "save": "Сохранить",
    "back": "Назад",
    "next": "Далее",
    "channelName": "Имя канала",
    "streamURL": "Ссылка на стрим",
    "channelsDesc": "Нужен хотя бы один источник",

    "mod": "Мод",
    "site": "Сайт",
    "repo": "Репозиторий",
    "bugReport": "Сообщить о баге",

    "direct": "Напрямую",
    "useSteam": "Установи мод используя Steam - актуальная версия с автообновлением",
    "useMOI": "Скачай через Modding Of Isaac - сайт с коллекцией модов",
    "useDirect": "Скачай ZIP-архив напрямую со страницы GitHub",

    "madeBy": "Создано Dorotos и VirtualZer0",
    "checkOnGithub": "Этот проект на GitHub",
    "enableLuadebug": "Как включить --luadebug",

    "luadebugMainDesc": "Для работы данного мода вам необходимо запустить игру с параметром --luadebug.",
    "luadebugDesc1": "1. Откройте ваш Steam и перейдите в библиотеку игр:",
    "luadebugDesc2": "2. Кликните правой кнопкой мыши по The Binding Of Isaac в левом списке игр и выберите \"Свойства...\":",
    "luadebugDesc3": "3. Найдите поле \"ПАРАМЕТРЫ ЗАПУСКА\" и введите",
    "luadebugDesc4": "4. Теперь вы можете запустить The Binding Of Isaac и нажать кнопку \"Начать\" на этом сайте:",

    "waitWhat": "Стоп, что?",
    "visitFromMobileDevice": "Нет абсолютно никаких причин посещать эту страницу с мобильного устройства. Но если вы уверены, что вам это действительно нужно, переключите ваш браузер на полную версию сайта в настройках. В какое странное время мы живем...",

    "gamemode": "Режим игры",
    "easy": "Легкий",
    "easyDesc": "Нет критических событий, таких как RERUN или Lost. Выберите, если зрители вас слишком сильно ненавидят",
    "default": "Стандарт",
    "defaultDesc": "Критические события имеют меньший шанс на появление. Идеальный баланс",
    "crazy": "Дурдом",
    "crazyDesc": "Все события имеют равный шанс, можно отбирать и выдавать любые предметы. Что ж, удачи",

    "status": "Статус",
    "connected": "Подключен",
    "loading": "Загрузка",
    "disconnected": "Отключен",
    "viewers": "Зрители",
    "total": "всего",
    "onCurrentPoll": "на опросе",
    "services": "Сервисы",
    "yes": "Да",
    "no": "Нет",
    "gameStateDisconnected": "Игра отключена",
    "gameStateConnected": "Игра подключена",
    "gameStatePaused": "Игра на паузе",
    "textPosition": "Положение текста",
    "resetTextPosition": "Установить по умолчанию",
    "keepTabActive": "Держите эту вкладку активной, иначе голосование может застрять",

    "gameConnected": "Теперь введите данные для Twitch и/или YouTube на сайте",
    "gameSetupDone": "Отлично! Голосование сейчас начнется",
    "awaitngData": "Обмен данными...",
    "selectItemForRemove": "Выберите предмет для удаления",
    "selectItem": "Выберите предмет",
    "pollGiveResult": "Вы получили",
    "pollNoneResult": "Вы ничего не получили",
    "pollRemoveResult": "Вы потеряли",
    "selectEvent": "Выберите событие",
    "selectedEvent": "Выбрано событие",
    "selectTrinket": "Выберите тринкет",

    "selectHearts": "Пиши \"больше\" или \"меньше\" для выбора сердца",
    "selectPockets": "Пиши \"больше\" или \"меньше\" для выбора карманного предмета",
    "selectKeys": "Пиши \"больше\" или \"меньше\" для выбора количества ключей",
    "selectBombs": "Пиши \"больше\" или \"меньше\" для выбора количества бомб",
    "selectCoins": "Пиши \"больше\" или \"меньше\" для выбора количества монет",

    "skipCurrentPoll": "Пропуск...",

    "newSub": "Новый подписчик",
    "newBits": "Вы получили Bits!",
    "newSuperchat": "Вы получили сообщение в суперчате!",

    "chat": "Чат",
    "openChat": "Открыть чат",
    "chatDesc": "...без сообщений с голосованием!",

    "coin": "монета",
    "bomb": "бомба",
    "key": "ключ",
    "battery": "Батарейка",
    "pill": "Пилюля",
    "card": "Карта",
    "redcard": "Красная карта",
    "momcard": "Emergency Contract",
    "humanitycard": "A Card Against Humanity",
    "holycard": "Holy Card",
    "creditcard": "Кредитная карта",
    "diceshard": "Обломок кубика",
    "blackrune": "Черная руна",
    "rune": "Руна",
    "sack": "Мешок",
    "goldenkey": "Золотой ключ",
    "goldenbomb": "Золотая бомба",
    "halfkeys": "Половину ключей",
    "halfbombs": "Половину бомб",
    "halfcoins": "Половину монет",

    "coinsSmallerThan5": "монеты",
    "bombsSmallerThan5": "бомбы",
    "keysSmallerThan5": "ключа",
    "batteriesSmallerThan5": "батарейки",
    "pillsSmallerThan5": "пилюли",
    "cardsSmallerThan5": "карты",
    "runesSmallerThan5": "руны",
    "sacksSmallerThan5": "мешка",

    "coins": "монет",
    "bombs": "бомб",
    "keys": "ключей",
    "batteries": "батареек",
    "pills": "пилюль",
    "cards": "карт",
    "runes": "рун",
    "sacks": "мешков",

    "heart": "сердце",
    "nothing": "ничего",
    "halfred": "1/2 красное",
    "halfsoul": "1/2 синее",
    "red": "красное",
    "soul": "синее",
    "black": "черное",
    "blended": "смешанное",
    "eternal": "вечное",
    "doubleRed": "два красных",
    "twitch": "Twitch",
    "gold": "золотое",
    "container": "контейнер",
    "bone": "костяное",
    "rainbow": "радужное",

    "redFlies": "красные мухи",
    "redSpiders": "красные пауки",
    "blueSpiders": "синие пауки",
    "blueFlies": "синие мухи",
    "prettyFly": "милая мушка",

  }
}
